<template>
  <section>
    <h1 v-html="text"></h1>
  </section>
</template>

<script>
export default {
  name: "ScreenLocked",
  props: {
    text: {
      type: String,
      required: true
    },
  }
}
</script>

<style scoped>
  section {
    font-size: 3rem;
  }

  h1{
    background-color: rgba(0, 0, 0, 0.68);
    color: white;
    padding: var(--base-spacing);
  }
</style>