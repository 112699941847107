<template>
  <div class="pannel">
    <h2>Informations</h2>
    <div class="container">
      <div class="news-free-text" v-html="news"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsPanel",
  props: {
    news: {
      required: true
    }
  },
}
</script>

<style scoped lang="scss">
.pannel{
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  /*background-color: rgba(117, 117, 117, 0.55);*/
  border-radius: calc(var(--border-radius));

  > div{
    padding: var(--base-spacing);
    > div{
      padding: var(--base-spacing);
      border-radius: calc(var(--border-radius));
      background-color: white;

      min-height: calc(var(--base-spacing) * 5);


    }
  }
}

h2{
  font-size: 3rem;
  font-weight: bold;
  padding: calc(var(--base-spacing) / 2);
}
</style>

<style lang="scss">
.news-free-text {
  text-align: left;

  font-size: 2.3rem;
  line-height: 3rem;

  h1{
    font-size: 3rem;
    padding-bottom: 1rem;
  }

  h2{
    font-size: 2.7rem;
    padding-bottom: 1rem;
  }

  h3{
    font-size: 2.4rem;
    padding-bottom: 1rem;
  }

  strong {
    font-weight: 1000;
  }
}
</style>