<template>
  <div id="page">
    <div v-for="room in data" :key="room.data.slug" class="container">
      <room-info :room="room.data" v-if="room.type === 'room'"></room-info>
      <news-panel v-if="room.type === 'news'" :news="room.data"></news-panel>
      <map-panel v-if="room.type === 'map'" :data="room.data.src"></map-panel>
      <photo-panel v-if="room.type === 'photo'"></photo-panel>
    </div>

  </div>
</template>

<script>
import RoomInfo from "@/components/Pages/Panels/RoomInfo.vue";
import MapPanel from "@/components/Pages/Panels/MapPanel.vue";
import NewsPanel from "@/components/Pages/Panels/NewsPanel.vue";
import PhotoPanel from "@/components/Pages/Panels/PhotoPanel.vue";

export default {
  name: "InfoPage",
  components: {PhotoPanel, NewsPanel, RoomInfo, MapPanel},
  props: {
    data: {
      type: Array,
      required: true
    }
  },
}
</script>

<style scoped lang="scss">
#page {
  display: flex;
  flex-direction: row;
  gap: var(--base-spacing);
  flex-wrap: wrap;
  justify-content: space-between;
}

.container{
  --splitter-width: calc(var(--base-spacing) / 8);
  flex-basis: 34%;
  flex-grow: 1;
  position: relative;

  min-width: calc(100vmin / 2);

  &:after{
    content: '';
    height: calc(100% - var(--base-spacing) * 2);
    width: var(--splitter-width);
    display: block;
    background-color: #2c3e50;
    top:var(--base-spacing);
    right: calc(var(--splitter-width) / -2 - var(--base-spacing) / 2);
    position: absolute;
    border-radius: calc(var(--splitter-width) / 2);

    /* hide vertical separator when vertical */
    @media only screen and (orientation: portrait) {
      width: 0;
    }
  }


  &:last-child:after{
    width: 0;
  }
}

</style>