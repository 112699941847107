import axios from 'axios';

export default {
    basePath: "https://eventws.ch/apps/screen/ajax",

    async isVisible(){
        const response = await axios.get(`${this.basePath}/visible.php`)

        if(response?.status === 200)
            return {
                visible: response.data?.visible ?? false,
                text: response.data?.text ?? "Polymanga n'a pas encore commencé"
            }

        return {
            visible: false,
            text: "Une erreur inconue s'est produite"
        }
    },

    async getRoomsPlanning(slugs){

        const slugsQueryParams = slugs
            .filter(s => s !== "NEWS")
            .map(s => `slugs[]=${s}`)
            .join("&")

        const path = `${this.basePath}/salles.php?${slugsQueryParams}`

        const response = await axios.get(path)

        if(response?.status === 200)
            return response.data?.salles ?? []

        return []
    },

    async getNews(){
        const response = await axios.get(`${this.basePath}/news.php`)

        if(response?.status === 200){
            return response.data?.news
        }

        return "";
    }
}