<script>
export default {
  name: "PhotoPanel",
}
</script>

<template>
  <div class="pannel">
    <div class="container">
      <h2>Photo Polymanga</h2>

      <div class="info">

        <img src="@/assets/photo_fin_23.jpg">
        <p>Rendez-vous sur les escaliers du Palais de Beaulieu à <b>17h30</b> pour la traditionnelle photo de clôture de Polymanga 2024 avec le maximum de monde possible.</p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.pannel{
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  /*background-color: rgba(117, 117, 117, 0.55);*/
  border-radius: calc(var(--border-radius));

  > div{
    padding: var(--base-spacing);
    padding-top: 0;
    > div{
      padding: var(--base-spacing);
      border-radius: calc(var(--border-radius));
      background-color: white;

      min-height: calc(var(--base-spacing) * 5);


    }
  }
}

h2{
  font-size: 3rem;
  font-weight: bold;
  padding: calc(var(--base-spacing) / 2);
}
</style>

<style lang="scss">
.info {
  text-align: left;

  font-size: 2.3rem;
  line-height: 3rem;

  img{
    width: 53%;
    margin: auto;
    display: block;
  }

  p{
    text-align: center;
    margin-top:  calc(var(--base-spacing) / 2);
  }

  b{
    font-weight: bold;
  }

  h1{
    font-size: 3rem;
    padding-bottom: 1rem;
  }

  h2{
    font-size: 2.7rem;
    padding-bottom: 1rem;
  }

  h3{
    font-size: 2.4rem;
    padding-bottom: 1rem;
  }

  strong {
    font-weight: 1000;
  }
}
</style>