<template>
  <div class="event" :class="styleClass">
    <div class="line hour" v-if="!event.is_flush">
      <span class="start">{{startHour}}</span> - <span class="end">{{endHour}}</span>
      <span class="delay" v-if="event && event.delay > 0"> +{{event.delay}} min</span>
    </div>
    <div class="line title">
      <span ref="title" v-if="event.is_flush != 1">{{event.name}}</span>
      <span ref="title" v-if="event.is_flush == 1">Vidage de salle</span>
    </div>
    <div class="line status" v-if="!event.is_flush">
      <span>{{status}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventDetail",
  props: {
    event:{
      required: true,
      type: Object
    }
  },
  data() {
    return {
      hourFormat: { hour12: false, hour: 'numeric', minute: "numeric" },
      scroll: {
        left: 0,
        direction: 1,
        amount: 0,
        timeout: undefined,
        speed: 3,
        pause: 1500,
      }

    }
  },
  mounted() {
    this.scroll.amount = this.$refs.title.offsetWidth - this.$refs.title.parentElement.offsetWidth

    if(this.scroll.amount > 0){
      this.timer = setTimeout(() => {
        this.updateScroll();
      }, this.scroll.pause)
    }

  },
  methods:{
    updateScroll() {
      let timeout = 20;

      if(this.scroll.left > this.scroll.amount || this.scroll.left < 0){
        this.scroll.direction = -this.scroll.direction;
        timeout = this.scroll.pause
      }

      this.scroll.left += this.scroll.speed * this.scroll.direction


      this.$refs.title.parentElement.scrollTo({
        left: this.scroll.left,
        behavior: "instant",
      })

      this.scroll.timeout = setTimeout(() => {
        this.updateScroll();
      }, timeout)
    }
  },
  unmounted() {
    if(this.scroll.timeout){
      clearInterval(this.scroll.timeout)
    }
  },
  computed: {
    styleClass: function(){
      if(this.event.is_flush){
        return "flush"
      }

      const fullness = this.fullness;

      if(this.event.is_full || this.event.is_canceled || fullness === 'FULL'){
        return "closed"
      }

      if(fullness === 'NEAR_FULL'){
        return "near_full"
      }

      return "open"
    },
    startHour: function (){
      const start = Date.parse(this.event.start)
      return new Intl.DateTimeFormat('fr-CH', this.hourFormat).format(start)
    },
    endHour: function (){
      const end = Date.parse(this.event.end)
      return new Intl.DateTimeFormat('fr-CH', this.hourFormat).format(end)
    },
    status: function (){
      if(this.event.is_flush){
        return "Vidage"
      }

      const fullness = this.fullness;

      if(this.event.is_full || this.event.is_canceled || fullness === 'FULL'){
        return "Complet"
      }

      if(fullness === 'NEAR_FULL'){
        return "Presque Complet"
      }

      return `Capacité: ${this.event.capacity} personnes`
    },
    fullness: function(){
      if(this.event.bookings === this.event.capacity){
        return "FULL"
      } else if(this.event.bookings > this.event.capacity * 0.8){
        return "NEAR_FULL"
      }
      return "OPEN"
    }
  }
}
</script>

<style scoped lang="scss">
.event{
  height: 100%;
  width: 100%;
  border-radius: calc(var(--border-radius));
  color: white;
  font-size: 2rem;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  padding: 0 var(--base-spacing);

  .hour, .status{
    font-weight: normal;
  }

  .delay{
    font-weight: bold;
  }
}

.title{
  font-size: 2.6rem;
  overflow: hidden;
  white-space: nowrap;
}

.flush{
  background-color: black;
}

.open{
  /*background-color: #1a941a;*/
  background: linear-gradient(334deg, #48b348 0%, #1a941a 100%);
}

.closed{
  /*background-color: #a40000;*/
  background: linear-gradient(296deg, #ad3737 0%, #a40000 100%);
}

.near_full{
  background: linear-gradient(296deg, #edd132 0%, #cba225 100%);
}
</style>