<template>
  <section>
    <div id="progress-bar" :class="{ progressing: isProgressing, resetProgress: !isProgressing }"
         v-bind:style="{transitionDuration: this.duration + 's'}"></div>
    <div id="page-number">{{ currentPage }}/{{ pageCount }}</div>
    <InfoPage :data="pageData"></InfoPage>
  </section>
</template>

<script>

import InfoPage from "@/components/Pages/InfoPage.vue";
import PlanningApi from "@/services/PlanningApi";
import WebAppApi from "@/services/WebAppApi";

export default {
  name: "InfoPages",
  components: {InfoPage},
  props: {
    duration: {
      type: Number,
      required: true
    },
    pagesSlugs: {
      type: Array,
      required: true
    }
  },
  async mounted() {
    this.pageCount = this.pagesSlugs.length

    this.nextPageData = await this.getPageData(0)
    await this.nextPage()
  },
  beforeUnmount() {
    clearTimeout(this.nextPageTimeout)
  },
  data() {
    return {
      isProgressing: false,
      pageCount: 0,
      currentPage: 0,
      pageData: [],
      nextPageData: [],
      nextPageTimeout: null
    }
  },
  methods: {
    async nextPage() {
      this.resetProgressBar()

      this.currentPage = this.nextPageNumber();

      this.pageData = this.nextPageData

      this.nextPageTimeout = setTimeout(() => this.nextPage(), this.duration * 1000)

      // Preload next page data
      this.nextPageData = await this.getPageData(this.currentPage - 1)
    },
    resetProgressBar() {
      this.isProgressing = false
      // Little hack to leave time for the browser to register the removal of the animation
      setTimeout(() => this.isProgressing = true, 100)
    },
    nextPageNumber() {
      if (this.currentPage >= this.pageCount) {
        return 1;
      }

      return this.currentPage + 1;
    },
    async getPageData(page) {
      const slugs = this.pagesSlugs[page] ?? []

      const [roomPlanning, news, activities] = await Promise.all([
            PlanningApi.getRoomsPlanning(slugs),
            PlanningApi.getNews(),
            WebAppApi.getActivities(),
      ])

      return slugs.map(s => {
        if (s === 'NEWS') {
          return {
            type: "news",
            slug: s,
            data: news
          }
        } else if(s=== 'map') {
          return {
            type: "map",
            slug: s,
            data: {
              src: "https://www.polymanga.com/PLAN_POLYMANGA2024_S.jpg"
            }
          }
        } else if(s === 'photo') {
          return {
            type: "photo",
            slug: s,
            data: {}
          }
        }

        const salle = roomPlanning
            .find(r => r.slug === s)

        for(let show of salle.shows.flat()){
          show.bookings = activities.find(a => a.id === show.id)?.bookings ?? 0
        }

        return {
          type: "room",
          slug: s,
          data: salle
        }
      }).filter(s => s.data != null)
    }
  },
}
</script>

<style scoped lang="scss">
section {
  height: calc(100% - var(--base-spacing) * 2);
  width: calc(100% - var(--base-spacing) * 2);
  margin: var(--base-spacing);
  display: grid;
}

#progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: calc(var(--base-spacing) / 2);
  background-color: #7643d6;
  transition-property: width;
  transition-timing-function: linear;
  z-index: 3;

  &.progressing {
    width: 100%;
  }

  &.resetProgress {
    transition-duration: 0s !important;
  }
}

#page-number {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #7643d6;
  color: white;
  padding: calc(var(--base-spacing) / 4) calc(var(--base-spacing) / 2);
  border-bottom-left-radius: calc(var(--base-spacing) / 2);
  font-size: 2rem;
  z-index: 3;
}

</style>