<template>
  <page-top></page-top>
  <ScreenLocked v-if="!isVisible" :text="notVisibleText"></ScreenLocked>
  <InfoPages v-if="isVisible" :duration="pageDuration" :pagesSlugs="pagesSlugs"></InfoPages>
  <PageBottom></PageBottom>
</template>

<script>
import ScreenLocked from "@/components/ScreenLocked.vue";
import PageBottom from "@/components/PageBottom.vue";
import PageTop from "@/components/PageTop.vue";
import PlanningApi from "@/services/PlanningApi";
import InfoPages from "@/components/InfoPages.vue";

export default {
  name: 'App',
  components: {
    InfoPages,
    PageTop,
    PageBottom,
    ScreenLocked,
  },
  data() {
    return {
      checkVisibleInterval: null,
      isVisible: false,
      notVisibleText: "",
      pageDuration: 20,
      pagesSlugs: []
    }
  },
  async mounted() {
    this.checkVisibleInterval = setInterval(async () => {
      await this.updatedIsVisible()
    }, 10000)

    this.parseUrlParams();

    await this.updatedIsVisible()
  },
  methods: {
    async updatedIsVisible(){
      const visibleData = await PlanningApi.isVisible();
      this.isVisible = visibleData.visible
      this.notVisibleText = visibleData.text
    },
    parseUrlParams(){
      const queryString = window.location.search;
      const searchParams = new URLSearchParams(queryString);

      const time = searchParams.get("time");

      if(time && parseInt(time) > 0){
        this.pageDuration = parseInt(time)
        console.trace(`Set page duration to ${this.pageDuration}s`)
      } else {
        console.warn(`Impossible to parse query parameter 'time' with the value: '${time}', defaulting to ${this.pageDuration}s`)
      }

      const pages = searchParams.getAll("pages[]");
      this.pagesSlugs = pages?.map(p => p.split(",")) ?? []

    }
  },
  beforeUnmount() {
    clearInterval(this.checkVisibleInterval)
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
  height: 100%;
  display: grid;
  grid-template-rows: 10% calc(90% - var(--footer-height)) var(--footer-height);
}
</style>
