<script>
export default {
  name: "MapPanel",
  props: {
    data: {
      required: true
    }
  },
}
</script>

<template>
  <div>
    <img v-bind:src="data">
  </div>
</template>

<style scoped lang="scss">
  div{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    padding: var(--base-spacing);
    padding-bottom: calc(var(--footer-height) + var(--base-spacing));
    z-index: 2;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
</style>