<template>
  <div class="pannel">
    <h2>{{room.name}}</h2>
    <div class="cols">
      <div class="col" v-for="(column, key) in room.salles_nbs.split(',')" :key="key">
        <h3 v-if="column !== ''">{{column}}</h3>
        <div class="events">
          <div class="event" v-for="show in room.shows[key].filter(s => s.is_visible === 1).slice(0,3)" :key="show.id">
            <event-detail :event="show"></event-detail>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

//import {toRefs, watch} from "vue";

import EventDetail from "@/components/Pages/Panels/EventDetail.vue";

export default {
  name: "RoomInfo",
  components: {EventDetail},
  props: {
    room: {
      required: true
    }
  },
  data() {
    return {
      columns: []
    }
  },
}
</script>

<style scoped lang="scss">

.pannel{
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  /*background-color: rgba(117, 117, 117, 0.55);*/
  border-radius: calc(var(--border-radius));
}

.cols {
  display: flex;
  flex-direction: row;
  gap: var(--base-spacing);
  flex-wrap: wrap;
  justify-content: space-between;

  height: 100%;
}

.col{
  display: flex;
  flex-direction: column;

  width: 100%;

  flex-basis: 32%;
  flex-grow: 1;
}

.events {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: var(--base-spacing);
  height: 100%;
  width: 100%;
  padding: var(--base-spacing);
}

.event{
  min-width: 0;

}

h2{
  font-size: 3rem;
  font-weight: bold;
  padding: calc(var(--base-spacing) / 2);
}

h3{
  font-size: 2.5rem;
  font-weight: bold;
}



@media (orientation: landscape) {
  .pannel{
    min-width: calc(50% - var(--base-spacing) / 2);
  }
}

@media (orientation: portrait) {
  .pannel{
    min-width: 100%;
  }
}
</style>