import axios from 'axios';

export default {
  basePath: "https://planning-api.eventws.ch/v1",

  async getActivities(){
    const response = await axios.get(`${this.basePath}/activities`)

    if(response?.status === 200){
      return response.data
    }

    return []
  }
}