<template>
  <footer>
    <img src="../assets/qrcode.png">
    <div>{{ time }}</div>
  </footer>
</template>

<script>
export default {
  name: "PageBottom",
  mounted() {
    this.timer = setInterval(() => {
      const date = new Date();
      const formatParts = new Intl.DateTimeFormat('fr-CH', this.dateFormatOptions).formatToParts(date);
      const time = new Intl.DateTimeFormat('fr-CH', this.timeFormatOptions).format(date);

      this.time = `${formatParts[0].value} ${formatParts[2].value} ${formatParts[4].value} ${time}`
    }, 1000)
  },
  data() {
    return {
      timer: null,
      time: "",
      dateFormatOptions: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      },
      timeFormatOptions: {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="scss">
footer {
  text-transform: capitalize;
  background-color: #7643d6;
  color: white;
  font-weight: bold;
  font-size: 2rem;
  display: grid;
  grid-template-columns: var(--footer-height) auto var(--footer-height);
  z-index: 3;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  > img {
    width: 100%;
  }
}
</style>