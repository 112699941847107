<template>
  <header>
    <img src="../assets/Logo_NoYear_Transparent.png">
  </header>
</template>

<script>
export default {
  name: "PageTop"
}
</script>

<style scoped>
header{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: calc(var(--base-spacing) / 2);
}
img{
  height: 90%;
}
</style>